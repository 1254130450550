(function ($, Drupal, languageToggle) {
  var LanguageMenu = {
    setup: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      self.init(context);
    },
    init: function (context) {
      var $languageMenu = $('.js-language-menu-v1', context);
      var $languageSelector = $languageMenu.find('.js-language-menu-selector');
      var SELECTED_ELEM_CLASS = 'language-menu__selected';
      var $selected = $languageMenu.find(`[data-lang="${languageToggle.getActiveLanguage()}"]`);

      $selected.addClass(SELECTED_ELEM_CLASS);

      $languageSelector.on('click', function () {
        var selectedLanguage = $(this).data()?.lang;
        /*
        Don't do anything if selecting the already active language
        */

        if (selectedLanguage && selectedLanguage !== languageToggle.getActiveLanguage()) {
          languageToggle.setCookie(selectedLanguage);
          languageToggle.switchLanguage(selectedLanguage);
        }
      });
    }
  };

  Drupal.behaviors.LanguageMenuV1 = {
    attached: false,
    attach: function (context) {
      LanguageMenu.setup(context);
    }
  };
})(jQuery, Drupal, languageToggle);
